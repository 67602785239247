.jobTitle {
  font-size: 13;
  font-family: "Space Grotesk";
  text-align: left;
  line-break: auto;
  word-break: break-word;
  margin-right: 1;
}

.displayname {
  font-size: 15;
  font-family: "Space Grotesk";
  text-align: left;
  margin-right: 1;
  font-weight: 700;
}
