.searchContainer {
  position: relative;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  width: 0;
}

.searchContainer.active {
  width: 100%;
  color: rgba(170, 202, 249, 0.8);
}
