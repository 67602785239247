.buttons {
  display: block;
}

.none {
  opacity: 0;
}

.buttons {
  opacity: 1;
  animation: opacity-effect 0.6s;
}

@keyframes opacity-effect {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
