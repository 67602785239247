.App {
  background-color: transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  background-image: url(../src/assets/background/waves.svg);
  background-repeat: no-repeat;
  background-position: bottom; /* Set the background image at the bottom */
  background-size: 100% auto; /* Stretch the SVG to cover the whole width */
  color: #525252;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  height: 100vh;
  background-color: #e9e4e4;
}

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
}

.row {
  display: flex;
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.match-parent-width {
  width: 100%;
}

.match-parent-height {
  height: 100%;
}

.match-screen-height {
  height: 100vh;
}

.small-bottom-distance {
  margin-bottom: 8px;
}

.default-bottom-distance {
  margin-bottom: 12px;
}

.large-bottom-distance {
  margin-bottom: 16px;
}

.xxl-bottom-distance {
  margin-bottom: 32px;
}

.default-row-spacing {
  padding: 8px 0px;
}

.medium-row-spacing {
  padding: 12px 0px;
}

.large-row-spacing {
  padding: 16px 0px;
}

.default-column-spacing {
  padding: 0px 8px;
  box-sizing: border-box;
}

.medium-column-spacing {
  padding: 0px 12px;
  box-sizing: border-box;
}

.large-column-spacing {
  padding: 0px 16px;
  box-sizing: border-box;
}

.position-relative {
  position: relative;
}
