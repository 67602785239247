.footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;
  width: 100%;
}

.logo-footer {
  height: 50px;
  width: 170px;
  margin-right: 0px;
}
