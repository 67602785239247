.header-page {
  text-align: center;
  position: absolute;
  z-index: 1;
  color: #333;
  width: 100%;
  font-family: "Days One", sans-serif;
}

.header-page h1 {
  letter-spacing: -1px;
  text-shadow: -2px -1px 1px #fff, 1px 2px 2px rgba(0, 0, 0, 0.2);
  font-weight: 300;
  font-size: 36px;
  margin: 0;
}

.header-page h2 {
  text-transform: uppercase;
  text-shadow: -2px -1px 1px #fff, 1px 1px 1px rgba(0, 0, 0, 0.15);
  font-weight: 300;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  margin: 0;
}
